import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import authenticationService from '../services/authentication.service';
import { useAuthentication } from '../session/AuthenticationProvider';
import tituloLogo from '../assets/img/brand/titulo-logo.png';
import './login.scss';
import { InputPassword } from '../commons/components/input-password';
import ContactsFooter from '../commons/components/ContactsFooter';
function Login({ history }) {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const auth = useAuthentication();

  const login = async (e) => {
    e.preventDefault();
    authenticationService
      .signIn(email, password)
      .then((user) => {
        auth.login(user);
        history.push('/');
      })
      .catch((err) => {
        if (err === 'forbidden_user') {
          setError('Usuario sin acceso. Sección exclusiva para administradores de empresas');
        } else if (err.response.data === 'Email NOT_VALIDATED') {
          setError('El email no ha sido validado. Por favor, revise su casilla de correo y siga las instrucciones.');
        } else {
          setError('Password o Email incorrectos');
        }
      });
  };
  return (
    <Container className="mw-100 w-100 wallpaper-logo-industry d-flex flex-column justify-content-center align-items-center overflow-hidden">
      <Row className="justify-content-center my-2">
        <Col xs="12" md="8" className="text-center">
          <h1>
            <img src={tituloLogo} alt="ministerio digital" className="img-fluid"></img>
          </h1>
        </Col>
      </Row>

      <Row className="justify-content-center mb-5 mt-2 w-100">
        <Col lg="6" md="8" xs="12" xl="5">
          <CardGroup>
            <Card className="p-3">
              <CardBody>
                <Form onSubmit={login}>
                  {error && <Alert color="danger">{error}</Alert>}
                  <h2>Inicio de sesión</h2>
                  <p className="text-muted">Inicio de sesión para empresas pequeñas y medianas</p>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="email" name="email" placeholder={t('emailPlaceholder')} required value={email} onChange={(e) => setEmail(e.target.value)} />
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputPassword
                      type={'password'}
                      placeholder={t('passwordPlaceholder')}
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      name={'password'}
                    />
                  </InputGroup>
                  <Row>
                    <Col xs="6">
                      <Button color="primary" className="px-4">
                        {t('login')}
                      </Button>
                    </Col>
                    <Col xs="6" className="text-right">
                      <Button tag={Link} to="/forgotpass" color="link" className="px-0">
                        {t('forgotPassword')}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="text-right">
                      <Button tag={Link} to="/register" color="link">
                        Registro
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>

      <ContactsFooter />
    </Container>
  );
}

export default Login;
